// Typography
$font-family-sans-serif: 'Oswald',
sans-serif;
$font-weight-base: 300;
$font-size-base: 1rem;
$line-height-base: 1.6;
$font-weight-bold: 400;
$green: #3fb618;
$novadent-blue: #032faa;
$novadent-darkblue: #002078;
$novadent-gray: #ddd;
/*#4471C4;*/
$novadent-link-hilite: #3fb618;
$novadent-background: #b7e059;
$novadent-filter-color: rgba(3, 47, 170, 1);
/*rgba(255, 153, 0, 1);*/
$novadent-filter-inactive-color: rgba(3, 47, 170, 0.2);
/*rgba(255, 153, 0, 0.2);*/