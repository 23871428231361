// Fonts
/* oswald-300 - latin-ext_latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-300.woff') format('woff'),
        /* Modern Browsers */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-300.svg#Oswald') format('svg');
    /* Legacy iOS */
}

/* oswald-regular - latin-ext_latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-regular.svg#Oswald') format('svg');
    /* Legacy iOS */
}

/* oswald-500 - latin-ext_latin */
@font-face {
    font-family: 'Oswald';
    font-style: bold;
    font-weight: 500;
    src: url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-500.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-500.woff') format('woff'),
        /* Modern Browsers */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/fonts/oswald-v48-latin-ext_latin/oswald-v48-latin-ext_latin-500.svg#Oswald') format('svg');
    /* Legacy iOS */
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


///////// custom css /////////
/* Move down content because we have a fixed navbar that is 50px tall */

body {
    padding-top: 60px;
    padding-bottom: 20px;
    background-color: #fff;
}

b,
strong,
.strong {
    font-weight: $font-weight-bold;
}

.navbar-default {
    /*background-color: rgb(183, 224, 89) !important;
    border-color: rgb(183, 224, 89);*/
    background-color: #CFFB59 !important;
    border-color: #CFFB59;
}


/*.navbar-default {
    background-image: -webkit-linear-gradient(top, #fff 0, #b7e059 100%);
    background-image: -o-linear-gradient(top, #fff 0, #b7e059 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#b7e059));
    background-image: linear-gradient(to bottom, #fff 0, #b7e059 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#b7e059', GradientType=0);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075)
}*/

.navbar {
    min-height: 50px;
    padding-top: 0;
    padding-bottom: 0;
}

nav .container {
    margin-top: 0 !important;
}

.navbar-brand {
    color: #000 !important;
    font-size: 28px;
    font-weight: 300;
    line-height: 28px;
    padding-top: 5px;
}


.navbar-brand img {
    width: auto;
    height: 25px;
}

@media (max-width: 767px) {
    .navbar-brand img {
        width: auto;
        height: 20px;
        margin-top: -5px;
    }

    .navbar-collapse {
        background-color: #fff;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .nav>li>a {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
    }
}

.navbar-default .navbar-toggle {
    border-color: #fff;
}

.navbar-default .navbar-nav>li>a {
    color: #000;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: #7BCB39;
    border-bottom: 2px solid #7BCB39;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
    color: #fff;
    background-color: transparent;
}

@media (max-width:767px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #000;
    }

    .navbar-default .navbar-nav>li>a:hover,
    .navbar-default .navbar-nav>li>a:focus {
        color: #fff;
        background-color: #090909
    }
}

.nav-item {
    text-align: center;
    line-height: 1.25;
}

li.dropdown i.fa-solid,
li.dropdown i.fa-regular {
    font-size: 16px;
}

.nav-link {
    padding: 8px 1rem;
    font-weight: 400;
    color: $novadent-blue !important;
}

.nav-link.messages-link:link,
.nav-link.messages-link:visited {
    color: red !important;
}

.navbar form {
    input {
        border-color: $novadent-blue;
    }

    .btn {
        background-color: $novadent-blue;
        border-color: $novadent-blue;
    }

    .btn:hover {
        color: $novadent-gray;
    }
}

.container {
    margin-top: 10px;
}

.breadcrumbs {
    font-weight: 400;
    font-size: 18px;
}

.webshop_breadcrumbs {
    font-weight: 400;
    font-size: 18px;
}

.main {
    margin-top: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
}

#content {
    min-height: 500px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $font-weight-bold;
}

h1,
.h1 {
    margin-bottom: 20px;
}

h1 a:link,
h1 a:visited,
h1 a:hover,
h1 a:active {
    color: #000;
    text-decoration: none;
}

h2,
.h2 {
    margin-top: 10px;
}

h1.sitetitle {
    margin-top: 10px;
    font-size: 30px
}

th {
    font-weight: 500;
}

/*.navbar-inverse .navbar-nav > li > a {
    color: white;
}*/

a:link,
a:visited,
a.btn-link:link,
a.btn-link:visited {
    color: $novadent-blue;
    text-decoration: none;
}

a:hover,
a:active,
a.btn-link:hover,
a.btn-link:active {
    color: $novadent-link-hilite;
    text-decoration: underline;
}

.navbar {

    a:link,
    a:visited,
    a.btn-link:link,
    a.btn-link:visited {
        color: $novadent-blue !important;
        text-decoration: none;
    }

    a:hover,
    a:active,
    a.btn-link:hover,
    a.btn-link:active {
        color: $novadent-darkblue !important;
    }

}

.messages-box,
.events-box {

    a:link,
    a:visited,
    a.btn-link:link,
    a.btn-link:visited {
        color: $novadent-blue !important;
        text-decoration: none;
    }

    a:hover,
    a:active,
    a.btn-link:hover,
    a.btn-link:active {
        color: $novadent-darkblue !important;
    }

}

.globonet {
    color: $novadent-gray !important;

    a:link,
    a:visited,
    a.btn-link:link,
    a.btn-link:visited {
        color: $novadent-gray !important;
        text-decoration: none;
    }

    a:hover,
    a:active,
    a.btn-link:hover,
    a.btn-link:active {
        color: $novadent-gray !important;
    }

}

/* arrow before link */
.productset_table,
.productset_info {
    a::before {
        content: "↗︎ ";
        font-size: 12px;
    }
}

// no arrow
a.popover-additional-info::before,
.products-blueimp-gallery a::before {
    content: none;
}

/* end arrow before link */

a.btn:link,
a.btn:visited {
    color: #fff;
}

a.btn:hover,
a.btn:active {
    color: #fff;
    text-decoration: none;
}

a.btn-secondary:link,
a.btn-secondary:visited {
    color: #333;
}

a.btn-secondary:hover,
a.btn-secondary:active {
    color: #333;

}

a.btn-link:link,
a.btn-link:visited {
    color: $novadent-blue;
}

a.btn-link:hover,
a.btn-link:active {
    color: $novadent-link-hilite;
}

.btn-warning,
.btn-success {
    background-color: $novadent-blue;
    border-color: $novadent-blue;
    color: #fff;
}

.btn-warning:hover,
.btn-success:hover {
    background-color: $novadent-darkblue;
    border-color: $novadent-darkblue;
    color: #fff;
}

.btn-success:disabled,
.btn-success.disabled {
    color: #666;
    opacity: .5;
    background-color: $novadent-filter-color;
    border-color: $novadent-filter-color;
}

.form-signin {
    max-width: 400px;
    /*padding: 15px;
    margin: 0 auto;*/
}


/*.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 10px;
}
.form-signin .checkbox {
    font-weight: 300;
}
form-signin .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.form-signin .form-control:focus {
    z-index: 2;
}*/

.form-signin input[type="text"],
.form-signin input[type="password"],
.form-signin input[type="email"] {
    margin-bottom: 10px;
}

.form-signin .btn {
    margin-top: 10px;
}

.form-group label {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.form-horizontal .control-label {
    text-align: left;
}

.form-horizontal .input2,
.input2 {
    width: 3em;
    display: inline;
}

.form-horizontal .input4,
.input4 {
    width: 4.5em;
    display: inline;
}

.form-horizontal .input8 {
    width: 8.5em;
    display: inline;
}

.buttons_and_pagination ul.pagination {
    float: right;
    margin-top: 0;
}

.buttons_and_pagination a.btn {
    float: right;
    margin-right: 20px;
}

ul.pagination {
    justify-content: flex-end !important;
}

/* language switcher */


/*.languageswitcher {
    margin-right: 0;
}
@media (min-width: 992px) {
    .languageswitcher {
        margin-left: 50px;
    }
}
@media (max-width: 767px) {
    .languageswitcher {
        margin-left: 20px;
    }
}
.languageswitcher .active {
    color: #777;
}*/

.lang-menu a {
    text-transform: uppercase;
}

.lang-menu .dropdown-menu {
    min-width: 0;
}

.users-list li {
    line-height: 2em;
}

.ui-autocomplete-loading {
    background: white url("/img/ui-anim_basic_16x16.gif") right center no-repeat;
}

.ordertable .totals td {
    font-weight: $font-weight-bold;
}

.ordertable .discount {
    color: red;
}

.ordertable .netto-values {
    color: blue;
}

.ordertable .discount-summary {
    background-color: rgba(255, 0, 0, 0.1);
}

.ordertable .input4 {
    width: 4em;
}

.ordertable .quantity {
    width: 3.5em;
}

.ordertable .video {
    font-size: 20px;
}

.ordertable .video a {
    color: red;
}

#orderform textarea {
    width: 100%;
}

.orderform-min-width-button {
    min-width: 110px;
}

.correct {
    color: green;
}

.wrong {
    color: red;
}

.addproducts #product_ids {
    width: 100px;
    display: inline;
}

.div_filter {
    padding: 20px 0;
    border-top: 1px solid #ddd;
}

.div_add_product {
    border-bottom: 1px solid #ddd;
}

table.not100 {
    width: auto;
}

.ordertable td.markers {
    padding-top: 12px;
    white-space: nowrap;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    display: inline-block;
}

.marker {
    font-size: 20px;
}

.beschaffungsartikel {
    color: red;
}

.medikament {
    color: red;
}

.legend {
    margin-top: 30px;
}

.legend tr {
    vertical-align: top;
}

.legend tr td:first-child {
    padding-right: 5px;
}

.u12 {
    color: blue;
}


/*.oxomi {
    background-color: #faf2cc;
}*/

.ordertable thead,
.productset_table thead,
.productgroup,
.orderstatus_table thead {
    background-color: #f9f9f9;
}

.ordertable tbody {
    border-top: 1px solid rgb(222, 226, 230) !important;
}

.orderform_row td,
.orderform_row_note td,
.orderform_row_aktion td {
    border: none !important;
}

.orderform_row td {
    padding-bottom: 3px !important;
}

.orderform_row_note td,
.orderform_row_aktion td {
    padding-top: 0 !important;
}

.orderform_row td.product-title {
    font-weight: $font-weight-bold;
}

.orderform_row_note td.label_note {
    padding-right: 0 !important;
}

.orderform_row_note td.field_note {
    display: table-cell;
}

.orderform_row_note td.field_note input {
    width: 100%;
}

.orderform_row_note .form-control-feedback {
    right: 8px;
}

.unavailable {
    background-color: rgba(200, 200, 200, 0.35);
}

.fa-none:before {
    content: "\2122";
    color: transparent !important;
}

.ordertype {
    font-weight: $font-weight-bold;
}

.aktion,
.aktion a {
    font-weight: $font-weight-bold;
    color: #FF9900;
    text-decoration: none !important;
}

.aktion-values {
    color: #FF9900;
}

.netto {
    font-weight: $font-weight-bold;
    color: #0000ff;
}

.lieferfrist {
    color: #ff8400;
}

.preisupdate {
    color: #000;
    font-weight: 300;
    background-color: #ffee00;
    padding: 1px 3px;
}

.table>tbody+tbody {
    border-top: none;
}

.table> :not(:first-child) {
    border-top: none;
}

.ordertable {
    border-top: 1px solid rgb(221, 221, 221);
}

#filter-form {
    margin-bottom: 10px;
}

#filter,
#product_id {
    /*length: 50px;*/
    max-width: 100%;
}

#submit_buttons {
    max-width: 280px;
}

.agb-addon,
.umtausch {
    font-weight: $font-weight-bold;
    color: red;
}

.bootbox-no-return .modal-title {
    color: red;
}

/* startseite */

.text_links,
.text_rechts {
    margin-top: 40px;
}

.text_6 {
    margin-bottom: 10px;
}


/*.edit-link {
    font-size: 12px;
}*/

.carousel-indicators li {
    background-color: #ccc;
    border: 1px solid #999;
}

#carousel-example-generic img {
    width: auto;
    height: 350px;
    max-height: 350px;
    margin: auto;
}

.video-js {
    max-width: 100%;
}

.vjs-default-skin .vjs-big-play-button {
    width: 2.6em !important;
    height: 1.6em !important;
}

.vjs-default-skin .vjs-big-play-button:before {
    line-height: 1.5em !important;
}

#oxomi_output_startseite .oxomi-catalog {
    margin-right: 0;
    width: 306px;
    height: 423px;
}

@media (max-width: 767px) {
    #oxomi_output_startseite .oxomi-catalog {
        width: 245px;
        height: 346px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #oxomi_output_startseite .oxomi-catalog {
        width: 157px;
        height: 212px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    #oxomi_output_startseite .oxomi-catalog {
        width: 212px;
        height: 300px;
    }
}

.oxomi-link {
    font-weight: 500;
}

#oxomi_safety {
    .oxomi-catalog {
        width: 100px;
        height: 137px;
    }
}

.helpbody {
    margin: 20px;
    padding: 0;
}

.note-link:link,
.note-link:visited {
    color: #666 !important;
    text-decoration: none !important;
}

.note-link:hover,
.note-link:active {
    color: #000 !important;
    text-decoration: none !important;
}

.note-link .fa-solid {
    font-size: 10px;
}


/* tablesorter */


/* tables */


/*table.tablesorter {
        font-family:arial;
        background-color: #CDCDCD;
        margin:10px 0pt 15px;
        font-size: 8pt;
        width: 100%;
        text-align: left;
}*/

table.tablesorter thead tr th,
table.tablesorter tfoot tr th {
    background-color: #eee;
    border: 1px solid #FFF;
    /*padding: 4px;*/
}

table.tablesorter thead tr .header {
    background-image: url(/img/bg.gif);
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
    font-weight: 500;
}


/*table.tablesorter tbody td {
        color: #3D3D3D;
        padding: 4px;
        background-color: #FFF;
        vertical-align: top;
}
table.tablesorter tbody tr.odd td {
        background-color:#F0F0F6;
}*/

table.tablesorter thead tr .headerSortUp {
    background-image: url(/img/asc.gif);
}

table.tablesorter thead tr .headerSortDown {
    background-image: url(/img/desc.gif);
}

table.tablesorter thead tr .headerSortDown,
table.tablesorter thead tr .headerSortUp {
    background-color: #8FBD27;
}

tr.search {
    background-color: #FFCC99;
}

tr.search td {
    vertical-align: middle;
}

.webshop-search-row {
    margin-bottom: 10px;
}

.webshop_filter_symbol {
    width: 80px;
}

.productset-panel {
    position: relative;
    margin-bottom: 30px;
}

/*.productset-panel-container {
    max-width: 240px;
}*/

.productset-panel-title {
    /*position: absolute;*/
    /*width: 100%;*/
    /*bottom: 0;*/
    /*height: 46px;*/
    left: 15px;
    right: 15px;
    padding: 3px 5px;
    overflow: hidden;
    text-align: center;
    line-height: 22px;
    border: 1px solid $novadent-blue;
}

.productset-panel-title.title-group,
.productset-panel-title.title-productset {
    height: 48px;
    max-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productset-panel .productset-panel-image {
    position: relative;
    border: 1px solid $novadent-blue;
    border-bottom: none;
    overflow: hidden;
}

.productset-panel .productset-panel-image.image-category {
    padding: 0;
    border: 1px solid $novadent-blue;
    border-right: none;
}

.productset-panel .productset-panel-producer {
    position: absolute;
    bottom: 1px;
    right: 1px;
    padding: 0 2px;
    background-color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.productset-panel .productset-panel-group-category {
    position: absolute;
    top: 1px;
    left: 1px;
    padding: 0 2px;
    background-color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
}

.productset-panel .productset-panel-mainset {
    position: absolute;
    top: 0;
    height: 3px;
    width: 100%;
    background-color: #B7E059;
}

.productset-panel .productset-panel-title {
    clear: both;
}

.productset-panel .productset-panel-title.title-category {
    clear: none;
    left: 0;
    right: 0;
    line-height: 1.1;
}

.productset-panel .productset-panel-title.title-category div {
    width: 100%
}

.productset-panel .productset-panel-image.image-category,
.productset-panel .productset-panel-title.title-category {
    height: 69px;
    display: flex;
    align-items: center;
    text-align: center;
}

.row-no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.productset_image {
    float: left;
    padding-right: 20px;
}

.on-orderlist {
    border: 2px solid $novadent-blue;
    /*background-color: #B7E059;*/
}

.orderlisticon {
    color: $novadent-blue;
    font-size: 18px;
}

/* collapse */

.collapse-row .collapse-toggle {
    padding-left: 0;
    font-weight: $font-weight-bold;
}

.collapse-row .collapse-toggle:before {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    font-size: 10px;
    padding-right: 4px;
    display: inline-block;
}

.collapse-row .collapse-toggle.collapsed:before {
    content: "\e080";
}

.collapse-row .collapse-toggle:hover::before {
    text-decoration: none;
}


/* end collapse */


/* admin markers */

.admin-marker-div {
    padding: 10px 10px 10px 30px;
    background-color: #B7E059;
}


/* end admin markers */

.selection a {
    font-weight: $font-weight-bold;
    color: #DC322F;
}

#oxomi_attachments {
    margin-bottom: 15px;
}

.alert a:link,
.alert a:visited {
    color: #fff;
    text-decoration: underline;
}

.alert-success a:link,
.alert-success a:visited,
.alert-danger a:link,
.alert-danger a:visited {
    color: #fff;
    text-decoration: none;
}

.alert a:hover,
.alert a:active {
    color: #000;
    text-decoration: underline;
}

.cart-menu {
    position: relative
}

.cart-menu .fa-solid {
    font-size: 18px;
}

.cart-menu .badge {
    position: relative;
    top: 4px;
    left: -3px;
}


/* login dropdown */

li.dropdown i {
    font-size: 12px;
}

.dropdown-login {
    min-width: 250px;
    font-size: 14px;
    padding: 12px 12px 6px 12px;
}

.dropdown-login .input-sm {
    margin-bottom: 3px;
}


/* end login dropdown */

#webshop_search select {
    width: 100%;
}

/*#webshop_search .btn-warning,
#webshop_search .btn-success {
    background-color: $novadent-filter-color;
    border-color: $novadent-filter-color;
}

#webshop_search .btn-warning:hover,
#webshop_search .btn-success:hover {
    color: $novadent-gray;
}*/

mark,
.mark {
    background: rgba(255, 255, 0, .7);
    padding: 0;
}

.filter_checkbox {
    width: 100%;
    background-color: $novadent-filter-inactive-color;
    /* #FF9900; */
    padding: 1px 8px 2px 8px
}

.filter-div {
    background-color: $novadent-filter-inactive-color;
    /* #FF9900; */
    padding: 5px;
}

.filter_active {
    background-color: $novadent-filter-color;
    /* #FF9900; */
}

.filter_checkbox label {
    font-weight: $font-weight-bold !important;
}

.text-filter .input-group {
    width: 100%;
}

.specialities_checkbox {
    min-height: 41px;
    padding-top: 9px;
}

.include_specialities {
    background-color: $novadent-filter-inactive-color;
    display: inline-block;
    font-weight: $font-weight-bold;
    padding: 6px 8px 8px 8px;
}

/* image mouseover in orderlist */


/* inspired from https://codepen.io/rrenula/pen/pIjBt */


/* animation */

.productpreview img {
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.productpreview {
    position: relative;
    float: left;
    width: 90px;
    height: 60px;
    z-index: 0;
}




/* Make sure z-index is higher on hover */


/* Ensure that hover image overlapped the others */

.productpreview:hover {
    z-index: 5;
}

/* Image is position nicely under li */

.productpreview img {
    left: 0;
    top: 0;
    width: 90px;
}

.productset_table .productpreview img {
    width: 60px;
}

.products-blueimp-gallery {
    position: relative;

    img,
    .img-placeholder {
        position: relative;
        width: 60px;
        height: 40px;
        padding: 2px;
        border: 1px solid #ddd;
        background-color: #fff;
    }

    img {
        cursor: zoom-in;
    }

    .img-placeholder {
        position: absolute;
        top: 4px;
        left: 4px;
    }
}


td.td-price {
    padding-bottom: 0 !important;
}

.productset_table,
.orderstatus_table {
    th {
        font-weight: 300;
    }

    .productpreview {
        width: 60px;
        height: 40px;
    }
}

.productset_table> :not(caption)>*>*,
.orderstatus_table> :not(caption)>*>* {
    border-bottom-width: 0;
    border-top-width: 1px;
    border-top-color: rgb(222, 226, 230);
}

// smaller gutter for 
@media (min-width: 992px) and (max-width: 1199px) {
    .productset_table> :not(caption)>*>* {
        padding: 0.25rem 0.25rem;
    }
}

.orderstatus_table .title-row td {
    border-top: none;
}

.productset_table {
    margin-bottom: 0;
}


.border-top {
    border-bottom-width: 1px;
    border-bottom-color: rgb(222, 226, 230);
    padding-top: 5px;
}

td.discounttext {
    border-top: none !important;
    padding-top: 0 !important;
}

.price-invalid {
    text-decoration: line-through;
}

.productpreview img:hover {
    position: absolute;
    width: 360px;
    /*margin-top: -100px;*/
    margin-left: -100px;
    /*top: 90%; */
    left: 75%;
    border: 1px solid #dddddd;
    /*padding: 2px;*/
    background: #fff;
}


/* end image mouseover in orderlist */

.popover-additional-info {
    float: right;
    color: $novadent-blue;
    margin-left: 5px;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-secondary a:hover,
.alert-secondary a:active {
    color: #fff;
    text-decoration: underline;
}

.alert-secondary a:link,
.alert-secondary a:visited {
    color: #000;
    text-decoration: underline;
}

.spinner-cover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

.table>tbody>tr>td.product_hinweis {
    padding-top: 0 !important;
    border-top: none !important;
}

.table>tbody>tr>td.product_hinweis,
div.product_hinweis {
    color: red;
}


/* big checkbox for AGB */

input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox+label.css-label {
    padding-top: 30px;
    height: 30px;
    width: 30px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
}

input[type=checkbox].css-checkbox:checked+label.css-label {
    background-position: 0 -30px;
}

label.css-label {
    background-image: url(/img/csscheckbox_9e0be0d3c50aa15eb49cfd5afdebcc01.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.not_available_panel {

    .productset-panel-image,
    .productset-panel-title {
        border: 1px solid rgba(3, 47, 170, 0.2);
    }

    .productset-panel-image {
        border-bottom: none;
    }

    a:link,
    a:visited {
        color: rgba(3, 47, 170, 0.4);
    }
}

.not_available {
    opacity: 0.2;
}

#oxomi_output_videos .oxomi-floater {
    margin-bottom: 24px
}

#oxomi_output_videos .oxomi-video-title {
    font-size: 16px;
    font-weight: $font-weight-bold;
}


/* stock info */

.stockinfo .ampel {
    width: auto;
    height: 30px;
}


/* end stock info */

.weekly-delivery {
    background-color: #ffffaa;
    padding: 5px 15px 1px 15px;
}

#orderform .popover {
    max-width: 500px;
}

.zwischentitel {
    /*border-top: 2px solid #3fb618;*/
    border-bottom: 1px solid #000;
}

.zwischentitel td {
    vertical-align: bottom !important;
    padding-top: 25px;
    padding-bottom: 5px;
}

.zwischentitel-text {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: #000;
}

tr.zwischentitel+tr td {
    border-top: none !important;
}

.shopbutton {
    background-color: transparent !important;
    border: 1px solid $novadent-blue;
}

.shopbutton:hover {
    background-color: $novadent-gray !important;
    border: 1px solid $novadent-blue;
}

.shopbutton .row {
    min-height: 60px;
    text-align: left;
}

.shopbutton .row div {
    font-size: 20px;
    color: $novadent-blue;
}

.shopbutton i {
    font-size: 40px;
}

.vertical-align {
    display: flex;
    align-items: center;
}

.ordermessages {
    background-color: #F5B041;
    padding: 5px 15px 0 15px;
    margin-bottom: 15px;
}

.ordermessage {
    padding-bottom: 5px;
}

.advert-preview {
    max-height: 120px;
    width: auto;
}

.searchtipps .row {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

#not_available {
    background-color: #F5B041;
    padding: 5px 15px 5px 15px;
}

#not_available td {
    vertical-align: top;
}

.sale_quantity_information {
    background-color: #F5B041;
    padding: 5px 15px 5px 15px;
    margin-bottom: 5px;
}

/* fallback for old bootstrap 3 classes */
.img-responsive {
    max-width: 100%;
    height: auto;
}

.btn-group-xs>.btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

/*.table> :not(:first-child) {
    border-top: 1px solid #ddd;
}*/

/*.table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border: none;
}*/

/* end fallback for old bootstrap 3 classes */

#frontpage-tabs .nav-item {
    padding-bottom: 20px;
}

#frontpage-tabs .nav-item .active .arrow-up {
    position: absolute;
    bottom: -16px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $novadent-gray;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.frontpage-tabs-content .tab-pane {
    border-top: 1px solid $novadent-gray;
    margin-top: -5px;
    border-bottom: 1px solid $novadent-gray;
}

.box-front {
    /*background-color: $novadent-background;*/
    border: 1px solid $novadent-gray;
    color: $novadent-blue;
    padding: 5px 10px;
    height: 100%;
    background-color: transparent;
    font-size: 12px;
    font-weight: 300;
    /*-webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;*/
    position: relative;
    text-align: left;
}

@media (max-width: 991px) {
    .box-front {
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
}

.box-front:hover {
    background-color: $novadent-gray;
}

.box-front-icon {
    float: left;
    font-size: 18px;
    margin-right: 10px;
}

@media (max-width: 576px) {
    .box-front-icon {
        display: none;
    }
}

.box-front-text-title {
    font-size: 16px;
    line-height: 18px;
}

@media (max-width: 767px) {
    .box-front-text-title {
        font-size: 16px;
        line-height: 18px;
    }
}

@media (max-width: 575px) {
    .box-front-text-title {
        font-size: 16px;
        line-height: 20px;
    }
}

footer,
.row-aktionen {
    /*background-color: $novadent-background;*/
    border-top: 1px solid $novadent-gray;
}

/*footer a:link,
footer a:visited {
    color: $novadent-blue;
}*/

.box-footer {
    padding: 15px 15px 15px 15px;
    /*color: $novadent-blue;*/
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.footer-language-switcher {
    /* text-align: right;*/
}

.footer-language-switcher .active {
    font-weight: $font-weight-bold;
}

/* frontpage */
.frontpage h1,
.frontpage h2,
.frontpage h3,
.frontpage h4 {
    color: $novadent-blue;
}

.col-1-5 {
    flex: 0 0 auto;
    width: 20%;
}

.col-4-5 {
    flex: 0 0 auto;
    width: 80%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    background-size: 50%;
    padding: 30px;
}

.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-prev:focus.carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon,
.carousel-control-next:focus .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, .5);
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
}

.messages-box,
.events-box {
    padding: 5px 10px;
    width: 100%;
    background-color: #d5ebf7;
    border: 1px solid $novadent-blue;

    h3 {
        font-weight: $font-weight-bold;
        font-size: 1rem;
        line-height: 1.25;
    }

    ul {
        padding-left: 20px;
        margin-bottom: 0;
        line-height: 1.25;
    }

    a:hover,
    a:active {
        text-decoration: underline;
    }

}

/*.messages-box {
    background-color: #FFCCCC;
    color: red;

    h3,
    a {
        color: red;
    }
}

.events-box {
    background-color: #CFFB59;
    color: green;

    h3,
    a {
        color: green;
    }
}*/

.adverts-current {
    a.anchor {
        display: block;
        position: relative;
        top: -80px;
        visibility: hidden;
    }
}

.adverts-current>div:first-child {
    border-right: 1px solid #ddd;
}

.messages-events-row .col-sm-6 {
    display: flex;
    align-items: stretch;
}

/* end frontpage */

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}


.webshop_show_right_col {
    .advert {
        text-align: right;
        margin-bottom: 15px;
    }

}

div.tooltip p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.no-results {
    font-size: 18px;
    font-weight: 500;
}

.novadent-table {
    thead th {
        background-color: #eee;
        border: 1px solid #FFF;
        font-weight: 500;
    }
}

#flash_urgent {
    ul {
        margin-bottom: 0;
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;

        li {
            font-weight: $font-weight-bold;

            a:link,
            a:visited {
                color: $novadent-blue;
            }

            a:hover,
            a:active {
                color: $novadent-darkblue;
                text-decoration: underline;
            }
        }
    }
}

/* ------- Print -------- */

@media print {
    a[href]:after {
        content: "";
    }

    /*.hide-print,*/
    p.breadcrumbs,
    div.div_filter,
    #orderform table.legend,
    #orderform #submit_buttons,
    .popover-additional-info {
        display: none;
    }
}